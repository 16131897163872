/**
 * @description
 * Convert an enum to an array of values coming from this enum
 * @example
 * If you want better typings, do:
 * qpEnumValuesToArray<typeof T, T>()
 * @template TEnum The type of enum
 * @template TEnumValue The type of values to receive
 * @param {Readonly<TEnum>} data The enum
 * @returns {TEnumValue[]} The list of values from the enum
 */
export function qpEnumValuesToArray<TEnum extends Record<string, TEnumValue>, TEnumValue = unknown>(data: Readonly<TEnum>): TEnumValue[] {
  return Object.values(data);
}
