import { AuthoritiesGuard } from '@admin/app/core/auth/authorities.guard';
import { UserManagementCreationComponent } from '@admin/app/user-management/user-management-creation/user-management-creation.component';
import { UserManagementResolver } from '@admin/app/user-management/user-management-creation/user-management.resolver';
import { UserManagementComponent } from '@admin/app/user-management/user-management.component';
import { QpGetFilterAndSortParamsGuard } from '@library/guards/qp-get-filter-and-sort-params/qp-get-filter-and-sort-params.guard';
import { Routes } from '@angular/router';

export const USER_MANAGEMENT_ROUTE: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    data: {
      pageTitle: 'userManagement.home.title',
      defaultSort: 'id,asc',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [AuthoritiesGuard, QpGetFilterAndSortParamsGuard],
  },
  {
    path: 'new',
    component: UserManagementCreationComponent,
    data: {
      pageTitle: 'userManagement.home.createLabel',
    },
    canActivate: [AuthoritiesGuard],
  },
  {
    path: ':id/edit',
    component: UserManagementCreationComponent,
    resolve: {
      user: UserManagementResolver,
    },
    data: {
      pageTitle: 'userManagement.home.editLabel',
    },
    canActivate: [AuthoritiesGuard],
  },
];
