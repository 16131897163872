// @ts-strict-ignore
import { BrandService } from '@admin/app/core/brand/brand.service';
import { BrandUser, IAdminBrandUser } from '@admin/app/core/user/brand-user.model';
import { IBrand } from '@admin/app/core/user/brand.model';
import { EUserProfile } from '@admin/app/core/user/user-profile.model';
import { EUserStatus } from '@admin/app/core/user/user.model';
import { UserActivationStateUpdateConfirmationModalComponent } from '@admin/app/user-management/user-management-activation-status-confirmation/user-activation-state-update-confirmation-modal.component';
import { UserManagementService } from '@admin/app/user-management/user-management-creation/user-management.service';
import { UserAdminDTO } from '@admin/dto/user-admin.dto';
import { QpButtonLabelComponent } from '@library/components/qp-button/components/qp-button-label/qp-button-label.component';
import { QpButtonComponent } from '@library/components/qp-button/qp-button.component';
import { EQpButtonSize, EQpButtonType } from '@library/components/qp-button/qp-button.models';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { qpEnumValuesToArray } from '@library/functions/enums/qp-enum-values-to-array';
import { IQpBrand } from '@library/models/qp-brand.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { QimaDialogService } from '@qima/ngx-qima';
import { cloneDeep, head, isEmpty, isNil } from 'lodash/index';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { EMPTY, filter, Observable, Subject, switchMap } from 'rxjs';
import { catchError, debounceTime, map, take, tap } from 'rxjs/operators';

interface IBrandUserUI {
  login: string;
  firstName: string;
  lastName: string;
  profile: EQpProfile;
  activated?: boolean;
  singleBrandId: number;
  brands: FormControl<number[]>;
  brandsId: FormControl<number[]>;
}

@UntilDestroy()
@Component({
  selector: 'admin-user-management-creation',
  templateUrl: './user-management-creation.component.html',
  styleUrls: ['./user-management-creation.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    QpLabelComponent,
    QpTextComponent,
    NzSelectModule,
    QpButtonComponent,
    QpIconComponent,
    QpButtonLabelComponent,
    TranslateModule,
  ],
})
export class UserManagementCreationComponent implements OnInit {
  public title = 'userManagement.creation.page.title';
  public isEditMode = false;
  public user: UserAdminDTO;
  public brands: number[];
  public profiles: string[]; // Enum values are incorrect and I set it as string in order to not have twice Factory in the drop down.
  public userCreationForm: FormGroup<ControlsOf<IBrandUserUI>>;
  public options: IBrand[] = [];
  public selectedBrand: number;
  public types: string;
  public readonly buttonSizes: typeof EQpButtonSize = EQpButtonSize;
  public readonly buttonTypes: typeof EQpButtonType = EQpButtonType;
  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public readonly userActivationStatuses = EUserStatus;

  private readonly queryBrandSubject$ = new Subject<string>();

  public constructor(
    private readonly _route: ActivatedRoute,
    private readonly _alertService: QpNotificationBarService,
    private readonly _brandService: BrandService,
    private readonly _userManagementService: UserManagementService,
    private readonly _dialogService: QimaDialogService
  ) {}

  public get isSupervisor(): boolean {
    return this.userCreationForm.controls.profile.value === EQpProfile.ROLE_SUPERVISOR;
  }

  public get loginField(): FormControl<string> {
    return this.userCreationForm.controls.login;
  }

  public get firstNameField(): FormControl<string> {
    return this.userCreationForm.controls.firstName;
  }

  public get lastNameField(): FormControl<string> {
    return this.userCreationForm.controls.lastName;
  }

  public get isServiceProvider(): boolean {
    return this.userCreationForm.controls.profile.value === EQpProfile.ROLE_SERVICE_PROVIDER;
  }

  public get isActivated(): boolean {
    return this.user?.activated ?? false;
  }

  public get userProfile(): EQpProfile | undefined {
    return this.user.profiles[0];
  }

  public ngOnInit(): void {
    this.userCreationForm = new FormGroup<ControlsOf<IBrandUserUI>>({
      login: new FormControl<string>('', [Validators.required, Validators.email]),
      firstName: new FormControl<string>('', Validators.required),
      lastName: new FormControl<string>('', Validators.required),
      profile: new FormControl<EQpProfile>(undefined, Validators.required),
      brandsId: new FormControl<number[]>(undefined),
      brands: new FormControl<number[]>(undefined),
      singleBrandId: new FormControl<number>(undefined),
    });

    // TODO : use the service UserService to get all the authorities when this component will allow inspector creation.
    this.profiles = Object.keys(EQpProfile);

    if (this._route.snapshot.data.user) {
      this.isEditMode = true;
      this.user = this._route.snapshot.data.user;

      if (this.userProfile === EQpProfile.ROLE_SUPERVISOR) {
        this.types = '';
      } else if (qpEnumValuesToArray(EUserProfile).includes(this.userProfile)) {
        this.types = EUserProfile[this.userProfile];
      } else {
        this.types = EUserProfile.ROLE_BRAND;
      }

      this.getAllBrands(this.user);
    }
  }

  public handleProfileSelection(): void {
    if (this.isSupervisor) {
      this.types = '';
      this.userCreationForm.controls.singleBrandId && this.userCreationForm.removeControl('singleBrandId');

      !this.userCreationForm.controls.brands &&
        this.userCreationForm.addControl('brands', new FormControl<number[]>(undefined, Validators.required));
    } else {
      this.types = EUserProfile[this.userCreationForm.controls.profile.value];
      this.userCreationForm.controls.brands && this.userCreationForm.removeControl('brands');
      !this.userCreationForm.controls.singleBrandId && this.userCreationForm.addControl('singleBrandId', new FormControl(undefined));
    }

    this.selectedBrand = null;
    let user: UserAdminDTO;

    if (this.user) {
      user = cloneDeep(this.user);
      user.email = this.userCreationForm.controls.login.value;
      user.firstName = this.userCreationForm.controls.firstName.value;
      user.lastName = this.userCreationForm.controls.lastName.value;
      user.profiles = [this.userCreationForm.controls.profile.value];
      user.brands = this.userProfile === this.userCreationForm.controls.profile.value ? user.brands : [];
    }

    this.getAllBrands(user);
  }

  public search(value: string): void {
    this.queryBrandSubject$.next(value);
  }

  public onSubmit(): void {
    const brandUser = BrandUser.create({
      id: null,
      login: this.userCreationForm.controls.login.value,
      firstName: this.userCreationForm.controls.firstName.value,
      lastName: this.userCreationForm.controls.lastName.value,
      profile: this.userCreationForm.controls.profile.value,
      brandsId: this.userCreationForm.controls.brands?.value ?? (isNil(this.selectedBrand) ? [] : [this.selectedBrand]),
    });

    if (this.isEditMode) {
      brandUser.id = this.user.id;
      this._userManagementService
        .updateBrandUser$({ ...brandUser, activated: this.user.activated })
        .pipe(
          catchError((): Observable<never> => {
            this._handleEditionError();

            return EMPTY;
          }),
          untilDestroyed(this)
        )
        .subscribe((): void => this._handleUpdatedBrandUser(brandUser.profile));
    } else {
      this._userManagementService
        .createBrandUser$(brandUser)
        .pipe(
          catchError((): Observable<never> => {
            this._handleCreationError();

            return EMPTY;
          }),
          untilDestroyed(this)
        )
        .subscribe((): void => this._handleCreatedBrandUser());
    }
  }

  public createNotifuseAccount(): void {
    this._userManagementService
      .createNotifuseUser$(this.user.id)
      .pipe(
        catchError((): Observable<never> => {
          this._alertService.error('userManagement.createNotifuseAccountFailure');

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((user: IAdminBrandUser): void => {
        this.user.notifuseUserId = user.notifuseUserId;
        this.user.notifuseUserHash = user.notifuseUserHash;
        this._alertService.success('userManagement.createNotifuseAccountSuccess');
      });
  }

  public populateForm(user: UserAdminDTO): void {
    this.userCreationForm.controls.login.setValue(user.email);
    this.userCreationForm.controls.firstName.setValue(user.firstName);
    this.userCreationForm.controls.lastName.setValue(user.lastName);
    this.userCreationForm.controls.profile.setValue(
      this.profiles.find(
        (authProfile): boolean =>
          // TODO(PL-24076): there is a mismatch between BE and FE profile naming, see https://github.com/asiainspection/qima-platform/pull/6466
          authProfile === user.profiles[0] || (authProfile === 'ROLE_ENTITY' && user.profiles[0] === 'ROLE_FACTORY')
      ) as EQpProfile
    );

    if (
      this.isEditMode &&
      [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR, 'ROLE_ENTITY'].includes(
        this.userCreationForm.controls.profile.value
      )
    ) {
      this.userCreationForm.controls.profile.disable();
    }

    if (user.profiles[0] !== EQpProfile.ROLE_SUPERVISOR) {
      const userBrands: IBrand[] = this.options.filter((brand: IBrand): boolean => user.brands.map((b): number => b.id).includes(brand.id));

      if (head(userBrands)) {
        this._manageFormInit(head(userBrands));
      } else if (!isEmpty(user.brands)) {
        const brandId = user.brands[0]?.id;

        qpAssert(brandId, 'Brand id is required');

        this._brandService
          .getBrandById$(brandId)
          .pipe(
            map((brand: IQpBrand): { id: number; name: string } => {
              return { id: brandId, name: brand.name };
            })
          )
          .subscribe((brand): void => {
            this._manageFormInit(brand, true);
          });
      }
    } else if (user.profiles[0] === EQpProfile.ROLE_SUPERVISOR) {
      const userBrands: number[] = this.brands.filter((brandId: number): boolean => user.brands.map((b): number => b.id).includes(brandId));

      this.userCreationForm.controls.singleBrandId && this.userCreationForm.removeControl('singleBrandId');
      this.userCreationForm.controls.brands.setValue(userBrands);
    }
  }

  public getAllBrands(user: UserAdminDTO): void {
    if ((!this.types || isEmpty(this.types.trim())) && user && user.profiles[0] !== EQpProfile.ROLE_SUPERVISOR) {
      this.populateForm(user);

      return;
    }

    this._getAllBrandsAndUserBrands(user)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: IQpBrand[]): void => {
          this._assignBrands(response, user);
        },
        error: (): void => this._alertService.error('brandManagement.access.brands-not-found'),
      });

    this.queryBrandSubject$
      .pipe(debounceTime(250))
      .pipe(untilDestroyed(this))
      .subscribe((search): void => this._queryBrand(search));
  }

  public hasNotifuseAccount(): boolean {
    return !isEmpty(this.user?.notifuseUserId);
  }

  public updateUserStatus(newStatus: EUserStatus): void {
    this._askUserStatusUpdateConfirmation$()
      .pipe(
        switchMap((): Observable<void> => this._updateUserStatus$(newStatus)),
        tap((): void => {
          this.user.activated = this._isUserActivated(newStatus);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _isUserActivated(userStatus: EUserStatus): boolean {
    return userStatus === EUserStatus.VALIDATED;
  }

  private _askUserStatusUpdateConfirmation$(): Observable<boolean> {
    const componentDialogRef = this._dialogService.open<boolean>(UserActivationStateUpdateConfirmationModalComponent);

    return componentDialogRef.closed.pipe(
      take(1),
      filter((isAnswered: boolean): boolean => isAnswered === true)
    );
  }

  private _updateUserStatus$(newStatus: EUserStatus): Observable<void> {
    return this._userManagementService.updateUserStatus$(this.user.id, { status: newStatus });
  }

  private _queryBrand(search: string): void {
    this._brandService
      .query(
        {
          types: this.types,
          name: search,
        },
        'GLOBAL'
      )
      .subscribe((response: HttpResponse<IBrand[]>): void => {
        this.options = response.body;
      });
  }

  /**
   * @description As getAllBrands return only 200 items, we need to merge from this list the user brands.
   * @param {UserAdminDTO} user to get the brands from.
   * @returns {Observable<IQpBrand[]>} - The list of brands to display in the multi select.
   */
  private _getAllBrandsAndUserBrands(user: UserAdminDTO): Observable<IQpBrand[]> {
    return this._brandService.getAllBrands({ types: this.types }).pipe(
      map((brands): IQpBrand[] => {
        user.brands.forEach((userBrand): void => {
          const brand = brands.find((brand): boolean => brand.id === userBrand.id);

          if (!brand) {
            brands.push({
              id: userBrand.id,
              name: userBrand.name,
              type: userBrand.type,
            });
          }
        });

        return brands;
      })
    );
  }

  private _assignBrands(response: IQpBrand[], user: UserAdminDTO): void {
    this.brands = response.map((brand: IQpBrand): number => brand.id);
    this.options = [...response];

    if (user) {
      this.populateForm(user);
    }
  }

  private _handleCreatedBrandUser(): void {
    this.userCreationForm.reset();
    this._alertService.success('userManagement.created');
  }

  private _handleUpdatedBrandUser(profile: string): void {
    this._alertService.success('userManagement.updated', { param: profile });
  }

  private _handleCreationError(): void {
    this._alertService.error('userManagement.error.creation');
  }

  private _handleEditionError(): void {
    this._alertService.error('userManagement.error.edition');
  }

  private _manageFormInit(brand: IBrand, isFetchNeeded = false): void {
    this.userCreationForm.controls.brands && this.userCreationForm.removeControl('brandsId');

    if (isFetchNeeded) {
      this.options.splice(-1, 1, brand);
    }

    this.selectedBrand = brand.id;
    this.userCreationForm.controls.singleBrandId.setValue(brand.id);
  }
}
