import { SERVER_API_URL } from '@admin/app/app.constants';
import { IAdminBrandUser, IBrandUser } from '@admin/app/core/user/brand-user.model';
import { IUserStatusUpdate } from '@admin/app/core/user/user.model';
import { UserAdminDTO } from '@admin/dto/user-admin.dto';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  public resourceUrl = `${SERVER_API_URL}api/users`;
  public adminResourceUrl = `${SERVER_API_URL}api/admin/users`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public createBrandUser$(brandUser: IBrandUser): Observable<IBrandUser> {
    return this._httpClient.post<IBrandUser>(this.resourceUrl, brandUser, { observe: 'body' });
  }

  public createNotifuseUser$(platformUserId: number): Observable<IAdminBrandUser> {
    return this._httpClient.post<IAdminBrandUser>(`${this.adminResourceUrl}/${platformUserId}/notifuse-account`, null, { observe: 'body' });
  }

  public updateBrandUser$(brandUser: IBrandUser): Observable<IBrandUser> {
    qpAssert(brandUser.id, 'Brand user id is required');

    return this._httpClient.put<IBrandUser>(`${this.adminResourceUrl}/${brandUser.id}`, brandUser, { observe: 'body' });
  }

  public getBrandUser$(platformUserId: number): Observable<UserAdminDTO> {
    return this._httpClient.get<UserAdminDTO>(`${this.adminResourceUrl}/${platformUserId}`);
  }

  public updateUserStatus$(userId: number, userStatusUpdate: IUserStatusUpdate): Observable<void> {
    return this._httpClient.put<void>(`${this.adminResourceUrl}/${userId}/status`, userStatusUpdate, { observe: 'body' });
  }
}
