<div class="user-management">
  <div class="table-responsive">
    <qp-datatable
      *ngIf="columnsConfigurations.length > 0"
      [headers]="headers"
      [hasActions]="false"
      [columnsConfigurations]="columnsConfigurations"
      [defaultFiltersAndSortsParams]="filtersAndSortsOnInit"
      [isLoading]="isFirstTimeLoading"
      (applyFiltersAndSorts)="applyFiltersAndSorts($event)"
    >
      <ng-container *ngIf="hasItemsToDisplay; else noResult">
        <qp-datarow *ngFor="let user of page.items; trackBy: trackIdentity" (click)="onViewUser(user)">
          <qp-datacell [width]="'4%'"> {{ user.id }} </qp-datacell>
          <qp-datacell [width]="'18%'">{{ user.email }}</qp-datacell>
          <qp-datacell [width]="'10%'">{{ user.firstName }}</qp-datacell>
          <qp-datacell [width]="'10%'">{{ user.lastName }}</qp-datacell>
          <qp-datacell [width]="'11%'">
            {{ user.companyName }}
          </qp-datacell>
          <qp-datacell [width]="'7%'">
            <div class="activated" *ngIf="user.activated">{{ 'userManagement.activation.activated' | translate }}</div>
            <div class="deactivated" *ngIf="!user.activated">{{ 'userManagement.activation.deactivated' | translate }}</div>
          </qp-datacell>
          <qp-datacell [width]="'5%'">{{ user.langKey }}</qp-datacell>
          <qp-datacell [width]="'10%'">
            <span class="badge badge-info">{{ user.profile }}</span>
          </qp-datacell>
          <qp-datacell [width]="'8%'">{{ user.createdDate | date: 'dd/MM/yy HH:mm' }}</qp-datacell>
          <qp-datacell [width]="'8%'">{{ user.lastModifiedDate | date: 'dd/MM/yy HH:mm' }}</qp-datacell>
          <qp-datacell [width]="'4%'">
            <qp-icon class="icon" [name]="user.notifuseUserId ? iconNames.IC_TICK : iconNames.IC_CLEAR"></qp-icon>
          </qp-datacell>
        </qp-datarow>
      </ng-container>

      <ng-template #noResult>
        <qp-no-result-table></qp-no-result-table>
      </ng-template>
    </qp-datatable>
  </div>

  <div *ngIf="isPaginationDisplayed && !isFirstTimeLoading" class="user-management__pagination">
    <div class="qp-row qp-justify-content-center">
      <qp-item-count [itemsPerPage]="page.pageSize" [page]="page.page" [total]="page.totalItems"></qp-item-count>
    </div>
    <div class="qp-row qp-justify-content-center">
      <nz-pagination
        (nzPageIndexChange)="pageChanged($event)"
        [nzPageIndex]="page.page"
        [nzPageSize]="page.pageSize"
        [nzTotal]="page.totalItems"
      >
      </nz-pagination>
    </div>
  </div>
</div>
