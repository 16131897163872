import { UserActivationStateUpdateConfirmationModalComponent } from './user-management-activation-status-confirmation/user-activation-state-update-confirmation-modal.component';
import { AdminSharedModule } from '@admin/app/shared/shared.module';
import { UserManagementCreationComponent } from '@admin/app/user-management/user-management-creation/user-management-creation.component';
import { UserManagementComponent } from '@admin/app/user-management/user-management.component';
import { USER_MANAGEMENT_ROUTE } from '@admin/app/user-management/user-management.route';
import { QpBadgeComponent } from '@library/components/qp-badge/qp-badge.component';
import { QpButtonComponent } from '@library/components/qp-button/qp-button.component';
import { QpCheckboxComponent } from '@library/components/qp-checkbox/qp-checkbox.component';
import { QpDatacellComponent } from '@library/components/qp-datacell/qp-datacell.component';
import { QpDatarowComponent } from '@library/components/qp-datarow/qp-datarow.component';
import { QpDatatableComponent } from '@library/components/qp-datatable/qp-datatable.component';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { QpItemCountComponent } from '@library/components/qp-item-count/qp-item-count.component';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpNoResultTableComponent } from '@library/components/qp-no-result-table/qp-no-result-table.component';
import { QpTextComponent } from '@library/components/qp-text/qp-text.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QimaButtonModule, QimaDialogModule } from '@qima/ngx-qima';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    AdminSharedModule,
    RouterModule.forChild(USER_MANAGEMENT_ROUTE),
    QpLabelComponent,
    QpTextComponent,
    TranslateModule,
    NzSelectModule,
    NzIconModule,
    QpIconComponent,
    QpButtonComponent,
    QpItemCountComponent,
    QpNoResultTableComponent,
    QpDatacellComponent,
    QpDatarowComponent,
    QpDatatableComponent,
    NzPaginationModule,
    CommonModule,
    QpCheckboxComponent,
    QpBadgeComponent,
    QimaDialogModule,
    QimaButtonModule,
    UserManagementCreationComponent,
    UserManagementComponent,
    UserActivationStateUpdateConfirmationModalComponent,
  ],
  exports: [RouterModule],
})
export class UserMgmtModule {
  public constructor(private readonly _translateService: TranslateService) {
    this._translateService.use('en');
  }
}
