import { UserManagementService } from '@admin/app/user-management/user-management-creation/user-management.service';
import { UserAdminDTO } from '@admin/dto/user-admin.dto';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserManagementResolver implements Resolve<Observable<UserAdminDTO>> {
  public constructor(
    private readonly _userManagementService: UserManagementService,
    private readonly _router: Router
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<UserAdminDTO> {
    const idParam = route.paramMap.get('id');

    qpAssert(idParam, 'User id is required');
    const platformUserId = parseInt(idParam, 10);

    return this._userManagementService.getBrandUser$(platformUserId).pipe(
      catchError((): Observable<never> => {
        void this._router.navigate(['/error/404'], { replaceUrl: true });

        return EMPTY;
      })
    );
  }
}
