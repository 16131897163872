<div>
  <h2>{{ (isEditMode ? 'userManagement.home.editLabel' : 'userManagement.home.createLabel') | translate }}</h2>
  @if (userCreationForm) {
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="userCreationForm">
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.login' | translate }}</qp-label>
          <qp-text
            [qpError]="loginField.dirty && loginField.invalid && (loginField.errors.required || loginField.errors.email)"
            data-cy="user-management-creation-login"
            formControlName="login"
            placeholder="{{ 'userManagement.login' | translate }}"
          ></qp-text>
          @if (loginField.dirty && loginField.invalid) {
            <div>
              @if (loginField.errors.required) {
                <qp-label error="true" type="informative">
                  {{ 'global.messages.validate.email.required' | translate }}
                </qp-label>
              }
              @if (loginField.errors.email) {
                <qp-label error="true" type="informative">
                  {{ 'global.messages.validate.email.invalid' | translate }}
                </qp-label>
              }
            </div>
          }
        </div>
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.firstName' | translate }}</qp-label>
          <qp-text
            [qpError]="firstNameField.dirty && firstNameField.invalid && firstNameField.errors.required"
            data-cy="user-management-creation-first-name"
            formControlName="firstName"
            placeholder="{{ 'userManagement.firstName' | translate }}"
          >
            @if (firstNameField.dirty && firstNameField.invalid) {
              <div>
                @if (firstNameField.errors.required) {
                  <qp-label error="true" type="informative">
                    {{ 'settings.messages.validate.firstName.required' | translate }}
                  </qp-label>
                }
              </div>
            }
          </qp-text>
        </div>
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.lastName' | translate }}</qp-label>
          <qp-text
            [qpError]="lastNameField.dirty && lastNameField.invalid && lastNameField.errors.required"
            data-cy="user-management-creation-last-name"
            formControlName="lastName"
            placeholder="{{ 'userManagement.lastName' | translate }}"
          >
            @if (lastNameField.dirty && lastNameField.invalid) {
              <div>
                @if (lastNameField.errors.required) {
                  <qp-label error="true" type="informative">
                    {{ 'settings.messages.validate.lastName.required' | translate }}
                  </qp-label>
                }
              </div>
            }
          </qp-text>
        </div>
        <div class="qp-form-group">
          <label
            >{{ 'userManagement.profiles' | translate }}
            <select
              (change)="handleProfileSelection()"
              class="qp-form-control"
              data-cy="user-management-creation-profile"
              formControlName="profile"
            >
              @for (profile of profiles; track profile) {
                <option [ngValue]="profile">{{ profile }}</option>
              }
            </select>
          </label>
        </div>
        <div class="qp-form-group">
          @if (userCreationForm.controls.profile.valid && !isSupervisor && selectedBrand) {
            <label>{{ (isServiceProvider ? 'userManagement.serviceProvider' : 'userManagement.brand') | translate }}</label>
          }
          <div>
            @if (isSupervisor) {
              <nz-select
                (nzOnSearch)="search($event)"
                [nzPlaceHolder]="(isServiceProvider ? 'userManagement.searchServiceProvider' : 'userManagement.searchBrand') | translate"
                [nzShowArrow]="true"
                nzMode="multiple"
                nzAutoClearSearchValue="false"
                formControlName="brands"
                nzServerSearch
                nzShowSearch
              >
                @for (option of options; track option) {
                  <nz-option [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                }
              </nz-select>
            }
          </div>
          <div>
            @if (!isSupervisor && userCreationForm.controls.profile.valid) {
              <nz-select
                (nzOnSearch)="search($event)"
                [(ngModel)]="selectedBrand"
                [nzDisabled]="isEditMode"
                [nzPlaceHolder]="(isServiceProvider ? 'userManagement.searchServiceProvider' : 'userManagement.searchBrand') | translate"
                [nzShowArrow]="true"
                formControlName="singleBrandId"
                nzServerSearch
                nzShowSearch
              >
                @for (option of options; track option) {
                  <nz-option [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                }
              </nz-select>
            }
          </div>
        </div>
        <div>
          <qp-button
            [isDisabled]="!userCreationForm.valid"
            [isSubmit]="true"
            [size]="buttonSizes.MEDIUM"
            [type]="buttonTypes.PRIMARY"
            data-cy="user-management-creation-save"
          >
            <qp-icon [name]="iconNames.IC_SAVE"></qp-icon>
            <qp-button-label>{{ 'entity.action.save' | translate }}</qp-button-label>
          </qp-button>
        </div>
      </form>
      <hr />
      <qp-label>{{ 'userManagement.status' | translate }}</qp-label>
      @if (isEditMode) {
        <div class="qp-form-group">
          <span> {{ user.status }}</span>
        </div>
      }
      <hr />
      @if (!!user) {
        <div class="user-management-activation">
          <div class="user-management-activation__state">
            <qp-label>{{ 'userManagement.activation.label' | translate }}</qp-label>
            <div
              class="user-management-activation__state-value user-management-activation__state-value{{
                isActivated ? '--activated' : '--deactivated'
              }}"
            >
              {{ (isActivated ? 'userManagement.activation.activated' : 'userManagement.activation.deactivated') | translate }}
            </div>
          </div>
          <div class="user-management-activation__actions">
            @if (!user.activated) {
              <qp-button
                [isDisabled]="user.status === 'PENDING'"
                (buttonClick)="updateUserStatus(userActivationStatuses.VALIDATED)"
                [size]="buttonSizes.SMALL"
                [type]="buttonTypes.SUCCESS"
                data-cy="user-management-activate"
              >
                <qp-button-label>{{ 'entity.action.activate' | translate }}</qp-button-label>
              </qp-button>
            }
            @if (user.activated) {
              <qp-button
                (buttonClick)="updateUserStatus(userActivationStatuses.SUSPENDED)"
                [size]="buttonSizes.SMALL"
                [type]="buttonTypes.DANGER"
                data-cy="user-management-deactivate"
              >
                <qp-button-label>{{ 'entity.action.deactivate' | translate }}</qp-button-label>
              </qp-button>
            }
          </div>
        </div>
      }
      <hr />
      <h2>QIMAconnect</h2>
      @if (isEditMode) {
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.connectId' | translate }}</qp-label>
          <span> {{ user.connectId ? user.connectId : ('userManagement.noConnectId' | translate) }}</span>
        </div>
      }
      <hr />
      <h2>Notifuse</h2>
      @if (isEditMode) {
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.notifuseUserId' | translate }}</qp-label>
          <span> {{ user.notifuseUserId ? user.notifuseUserId : ('userManagement.noNotifuseUserId' | translate) }}</span>
        </div>
      }
      @if (isEditMode) {
        <div class="qp-form-group">
          <qp-label>{{ 'userManagement.notifuseUserHash' | translate }}</qp-label>
          <span> {{ user.notifuseUserHash ? user.notifuseUserHash : ('userManagement.noNotifuseUserHash' | translate) }}</span>
        </div>
      }
      <div class="btn-account">
        <qp-button
          (buttonClick)="createNotifuseAccount()"
          [isDisabled]="!isEditMode || hasNotifuseAccount() || !user?.activated"
          [size]="buttonSizes.LARGE"
          [type]="buttonTypes.PRIMARY"
          data-cy="user-management-creation-save"
        >
          <qp-icon [name]="iconNames.IC_SAVE"></qp-icon>
          <qp-button-label>{{ 'userManagement.createNotifuseAccount' | translate }}</qp-button-label>
        </qp-button>
      </div>
      <hr />
    </div>
  }
</div>
