// @ts-strict-ignore
import { AccountService } from '@admin/app/core/auth/account.service';
import { Account } from '@admin/app/core/user/account.model';
import { IPlatformUserConsultationDTO } from '@admin/app/core/user/platformUserConsultationDTO.model';
import { UserService } from '@admin/app/core/user/user.service';
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { QpPageList } from '@library/classes/qp-page-list/qp-page-list';
import { QpDatacellComponent } from '@library/components/qp-datacell/qp-datacell.component';
import { QpDatarowComponent } from '@library/components/qp-datarow/qp-datarow.component';
import { QpDatatableComponent } from '@library/components/qp-datatable/qp-datatable.component';
import {
  EQpDatatableConfigurationType,
  IQpDatatableColumnConfigurations,
  IQpDatatableFilterAndSortParam,
} from '@library/components/qp-datatable/qp-datatable.models';
import { QpDatatableService } from '@library/components/qp-datatable/qp-datatable.service';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpItemCountComponent } from '@library/components/qp-item-count/qp-item-count.component';
import { QpNoResultTableComponent } from '@library/components/qp-no-result-table/qp-no-result-table.component';
import { IQpTable } from '@library/components/qp-table/qp-table.models';
import { QpTableService } from '@library/components/qp-table/services/qp-table.service';
import { qpAsParams } from '@library/functions/qp-as-params/qp-as-params';
import { IQpConsultationPage } from '@library/models/qp-consultation-page.models';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { isEmpty } from 'lodash/index';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [QpTableService],
  standalone: true,
  imports: [
    NgIf,
    QpDatatableComponent,
    NgFor,
    QpDatarowComponent,
    QpDatacellComponent,
    QpIconComponent,
    QpNoResultTableComponent,
    QpItemCountComponent,
    NzPaginationModule,
    TranslateModule,
    DatePipe,
  ],
})
export class UserManagementComponent extends QpPageList<IPlatformUserConsultationDTO> implements OnInit {
  public currentAccount: Account;
  public hasItemsToDisplay = false;
  public headers: string[] = [];
  public columnsConfigurations: IQpDatatableColumnConfigurations[] = [];
  public tableConfiguration: IQpTable = { columns: [] };
  public readonly iconNames: typeof EQpIconName = EQpIconName;

  public constructor(
    public readonly router: Router,
    private readonly _userService: UserService,
    private readonly _qpNotificationBarService: QpNotificationBarService,
    private readonly _accountService: AccountService,
    private readonly _qpDatatableService: QpDatatableService
  ) {
    super();

    this._watchQueryParamsChange$().pipe(untilDestroyed(this)).subscribe();
    this.filtersAndSortsOnInit = this._qpDatatableService.getFilterAndSortForExistingParams();
  }

  public ngOnInit(): void {
    void this._accountService.identity().then((account: Account): void => {
      this.currentAccount = account;
    });

    this._initDatatable();
  }

  public get isPaginationDisplayed(): boolean {
    return this.page.totalItems > this.page.pageSize && this.columnsConfigurations.length > 0;
  }

  public setActive(user: IPlatformUserConsultationDTO, isActivated: boolean): void {
    user.activated = isActivated;

    this._userService.update(user).subscribe({
      next: (response): void => {
        if (response.status === EQpHttpStatusCode.OK) {
          void this.applyFiltersAndSorts(this.filtersAndSortsParams);
        } else {
          this._qpNotificationBarService.error('userManagement.error.edition');
        }
      },
    });
  }

  public trackIdentity(_index: number, item: IPlatformUserConsultationDTO): number {
    return item.id;
  }

  public pageChanged(newPageIndex: Readonly<number>): void {
    const queryParams: Params = qpAsParams(
      {
        page: newPageIndex,
        size: this.page.pageSize,
      },
      this.filtersAndSortsParams
    );

    void this._updateUrlQueryParams(queryParams);
  }

  public applyFiltersAndSorts(filtersAndSortsParams: IQpDatatableFilterAndSortParam[]): Promise<boolean> {
    const queryParams: Params = qpAsParams(
      {
        page: 1,
        size: this.page.pageSize,
      },
      filtersAndSortsParams
    );

    return this._updateUrlQueryParams(queryParams);
  }

  public onViewUser(user: IPlatformUserConsultationDTO): void {
    void this.router.navigate([`/user-management/${user.id}/edit`]);
  }

  protected _loadPage$(params: Readonly<IQpPageQuery>): Observable<IQpConsultationPage<IPlatformUserConsultationDTO>> {
    return this._userService
      .getUsers$(
        {
          page: params.page - 1,
          size: params.size,
        },
        this.filtersAndSortsParams
      )
      .pipe(
        tap({
          next: (page: Readonly<IQpConsultationPage<IPlatformUserConsultationDTO>>): void => {
            this._setPage(page);
          },
          error: (): void => {
            this._qpNotificationBarService.error('account.edition.error');
          },
        }),
        finalize((): void => {
          this.isFirstTimeLoading = false;
        }),
        take(1)
      );
  }

  protected _setPage(page: IQpConsultationPage<IPlatformUserConsultationDTO>): void {
    this.page = page;
    this.hasItemsToDisplay = !isEmpty(this.page.items);
  }

  private _initDatatable(): void {
    this.headers = [
      'global.field.id',
      'userManagement.login',
      'userManagement.firstName',
      'userManagement.lastName',
      'userManagement.brandName',
      'userManagement.activation.label',
      'userManagement.langKey',
      'userManagement.profiles',
      'userManagement.createdDate',
      'userManagement.lastModifiedDate',
      'userManagement.notifuse',
    ];

    this.columnsConfigurations = [
      {
        property: 'id',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
        ],
      },
      {
        property: 'login',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
          {
            type: EQpDatatableConfigurationType.SEARCH,
          },
        ],
      },
      {
        property: 'firstName',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
          {
            type: EQpDatatableConfigurationType.SEARCH,
          },
        ],
      },
      {
        property: 'lastName',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
          {
            type: EQpDatatableConfigurationType.SEARCH,
          },
        ],
      },
      {
        property: 'companyName',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
          {
            type: EQpDatatableConfigurationType.SEARCH,
          },
        ],
      },
      {
        property: '',
        configurations: [],
      },
      {
        property: 'langKey',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
        ],
      },
      {
        property: 'profiles',
        configurations: [],
      },
      {
        property: 'createdDate',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
        ],
      },
      {
        property: 'lastModifiedDate',
        configurations: [
          {
            type: EQpDatatableConfigurationType.SORT,
          },
        ],
      },
    ];
  }
}
