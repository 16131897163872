import { PlatformUserStatus } from '@library/dto-enums/platform-user-status.dto-enum';
import { IQpBrandWithAddress } from '@library/models/qp-brand.models';
import { EQpProfile } from '@library/models/qp-profile.models';

export interface IBrandUser {
  id?: number;
  login: string;
  firstName: string;
  lastName: string;
  profile: EQpProfile;
  brandsId: number[];
  activated?: boolean;
  status?: PlatformUserStatus;
  notifuseUserId?: string;
  notifuseUserHash?: string;
}

export interface IAdminBrandUser {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  profiles: EQpProfile[];
  brands: IQpBrandWithAddress[];
  status?: PlatformUserStatus;
  activated?: boolean;
  notifuseUserId?: string;
  notifuseUserHash?: string;
}

export class BrandUser {
  public static create(brandUser: {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    profile: EQpProfile;
    brandsId: number[];
    status?: PlatformUserStatus;
    activated?: boolean;
    notifuseUserId?: string;
    notifuseUserHash?: string;
  }): IBrandUser {
    return new BrandUser(
      brandUser.id,
      brandUser.login,
      brandUser.firstName,
      brandUser.lastName,
      brandUser.profile,
      brandUser.brandsId,
      brandUser.status,
      brandUser.activated,
      brandUser.notifuseUserId,
      brandUser.notifuseUserHash
    );
  }

  private constructor(
    public id: number,
    public login: string,
    public firstName: string,
    public lastName: string,
    public profile: EQpProfile,
    public brandsId: number[],
    public status?: PlatformUserStatus,
    public activated?: boolean,
    public notifuseUserId?: string,
    public notifuseUserHash?: string
  ) {}
}
