// @ts-strict-ignore
import { SERVER_API_URL } from '@admin/app/app.constants';
import { IPlatformUserConsultationDTO } from '@admin/app/core/user/platformUserConsultationDTO.model';
import { createRequestOption } from '@admin/app/shared/util/request-util';
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpAsHttpParams } from '@library/functions/qp-as-http-params/qp-as-http-params';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { IQpConsultationPage, QpConsultationPage } from '@library/models/qp-consultation-page.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
  public resourceUrl = `${SERVER_API_URL}api/users`;
  public resourceAdminUrl = `${SERVER_API_URL}api/admin/users`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public getUsers$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IPlatformUserConsultationDTO>> {
    const filtersAndSortsWithDefaults: IQpDatatableFilterAndSortParam[] = this.applyDefaultSortsIfNone(filtersAndSorts, [
      { type: 'sort', value: 'id,asc' },
    ]);
    const params = qpAsHttpParams(pageQuery, filtersAndSortsWithDefaults);

    return this._httpClient.get<IPlatformUserConsultationDTO[]>(`${this.resourceAdminUrl}`, { params, observe: 'response' }).pipe(
      map((response): IQpConsultationPage<IPlatformUserConsultationDTO> => {
        const items: IPlatformUserConsultationDTO[] = response.body;
        const totalItems = parseInt(response.headers.get('x-total-count'));
        const totalPageCount = parseInt(response.headers.get('x-total-page'));

        return QpConsultationPage.create<IPlatformUserConsultationDTO>({
          items,
          page: pageQuery.page + 1,
          pageSize: pageQuery.size,
          totalItems,
          totalPageCount,
        });
      })
    );
  }

  public applyDefaultSortsIfNone(
    filtersAndSorts: IQpDatatableFilterAndSortParam[],
    defaultSorts: IQpDatatableFilterAndSortParam[] = []
  ): IQpDatatableFilterAndSortParam[] {
    const copy: IQpDatatableFilterAndSortParam[] = !qpHasSorts(filtersAndSorts)
      ? [...qpGetFilters(filtersAndSorts), ...defaultSorts]
      : [...filtersAndSorts];

    return copy;
  }

  public create(user: IPlatformUserConsultationDTO): Observable<HttpResponse<IPlatformUserConsultationDTO>> {
    return this._httpClient.post<IPlatformUserConsultationDTO>(this.resourceUrl, user, { observe: 'response' });
  }

  public update(user: IPlatformUserConsultationDTO): Observable<HttpResponse<IPlatformUserConsultationDTO>> {
    return this._httpClient.put<IPlatformUserConsultationDTO>(this.resourceAdminUrl, user, { observe: 'response' });
  }

  public find(login: string): Observable<HttpResponse<IPlatformUserConsultationDTO>> {
    return this._httpClient.get<IPlatformUserConsultationDTO>(`${this.resourceUrl}/${login}`, { observe: 'response' });
  }

  public query(req?: Record<string, unknown>): Observable<HttpResponse<IPlatformUserConsultationDTO[]>> {
    const options = createRequestOption(req);

    return this._httpClient.get<IPlatformUserConsultationDTO[]>(this.resourceAdminUrl, { params: options, observe: 'response' });
  }

  public authorities(): Observable<string[]> {
    return this._httpClient.get<string[]>(`${SERVER_API_URL}api/users/authorities`);
  }
}
