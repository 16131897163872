<qima-dialog-close-icon (click)="cancel()"></qima-dialog-close-icon>

<qima-dialog-title>{{ 'userManagement.activation.confirmationModal.header' | translate }}</qima-dialog-title>

<qima-dialog-content class="content">{{ 'userManagement.activation.confirmationModal.content' | translate }}</qima-dialog-content>

<qima-dialog-actions>
  <qima-button
    [qimaButtonStyle]="buttonStyles.PRIMARY_DESTRUCTIVE"
    [qimaButtonSize]="buttonSizes.LARGE"
    [qimaButtonLabel]="'userManagement.activation.confirmationModal.confirm' | translate"
    (qimaButtonClick)="confirm()"
    class="action"
  ></qima-button>
  <qima-button
    [qimaButtonStyle]="buttonStyles.SECONDARY"
    [qimaButtonSize]="buttonSizes.LARGE"
    [qimaButtonLabel]="'userManagement.activation.confirmationModal.cancel' | translate"
    (qimaButtonClick)="cancel()"
    class="action"
  ></qima-button>
</qima-dialog-actions>
