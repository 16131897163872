import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EQimaButtonSize, EQimaButtonStyle, QimaDialogModule, QimaButtonModule } from '@qima/ngx-qima';

@Component({
  selector: 'admin-user-activation-state-update-confirmation-modal',
  templateUrl: './user-activation-state-update-confirmation-modal.component.html',
  styleUrls: ['./user-activation-state-update-confirmation-modal.component.scss'],
  standalone: true,
  imports: [QimaDialogModule, QimaButtonModule, TranslateModule],
})
export class UserActivationStateUpdateConfirmationModalComponent {
  public readonly buttonSizes: typeof EQimaButtonSize = EQimaButtonSize;
  public readonly buttonStyles: typeof EQimaButtonStyle = EQimaButtonStyle;

  public constructor(private readonly _dialogRef: DialogRef<boolean, UserActivationStateUpdateConfirmationModalComponent>) {}

  public confirm(): void {
    this._dialogRef.close(true);
  }

  public cancel(): void {
    this._dialogRef.close(false);
  }
}
